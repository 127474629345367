import React, { useContext } from 'react'
import Specifications from '../../Specifications/Specifications'
import Clock from '../../../images/icons/Clock'
import Eur from '../../../images/icons/Eur'
import './Expedition.scss'
import Button from '../../Button/Button'
import { HeaderContext } from '../../Header/Header'

export default (props) => {
	const { courseData } = props
	const HeaderCTX = useContext(HeaderContext)

	if (!courseData || !courseData.acf) {
		return null
	}

	// const firstTimeTable =
	// 	courseData &&
	// 	courseData.acf.variations &&
	// 	courseData.acf.variations[0] &&
	// 	courseData.acf.variations[0].times
	// 		? courseData.acf.variations[0].times
	// 		: false
	// const [currentVariant, setCurrentVariant] = useState(firstTimeTable)
	// let keyCount = 0
	// let choiceCount = 1
	//
	// function updateTimes(timesTable, event) {
	// 	setCurrentVariant(timesTable)
	// }
	//
	// function createTimeSlotOption(choice, variantTimes = false) {
	// 	let usableDays = days
	// 	if (
	// 		courseData.acf.variations &&
	// 		courseData.acf.variations.length &&
	// 		variantTimes
	// 	) {
	// 		const variantDays =
	// 			variantTimes.body && variantTimes.body.length
	// 				? variantTimes.body
	// 				: []
	//
	// 		const variantHeaders =
	// 			variantTimes.header && variantTimes.header.length
	// 				? variantTimes.header.map((header) => header.c)
	// 				: []
	//
	// 		usableDays = transformTableData(variantDays, variantHeaders)
	// 	}
	//
	// 	return (
	// 		<select name={choice + '-choice'} id={choice + '-choice'}>
	// 			<option disabled hidden>
	// 				{choiceCount && choiceCount++}e keus
	// 			</option>
	// 			{usableDays.length
	// 				? usableDays.map((day, i) => {
	// 						keyCount++
	// 						return day.times.length
	// 							? day.times.map((time) => {
	// 									keyCount++
	// 									if (time !== '-') {
	// 										return (
	// 											<option
	// 												key={`${day.name}-${time}-${i}-${keyCount}`}
	// 												value={`${day.name} - ${time}`}
	// 											>
	// 												{day.name} - {time}
	// 											</option>
	// 										)
	// 									}
	// 							  })
	// 							: null
	// 				  })
	// 				: null}
	// 		</select>
	// 	)
	// }

	return (
		<React.Fragment>
			<section className={'sign-up-form'}>
				<article>
					<p>
						Aanmelden{' '}
						{courseData.acf.reis_informatie_blok.reis_titel}
					</p>
					<p>
						Na dit formulier bij ons binnen is sturen wij binnenkort
						een bevestiging van je reservering per mail en krijgt u
						meer informatie over de volgende stappen en betaling.
						Voor vragen en/of opmerking kunt u contact opnemen via{' '}
						<a
							target={'_blank'}
							href="mailto:info@inbalansalkmaar.nl"
							rel="noopener noreferrer"
						>
							info@inbalansalkmaar.nl
						</a>{' '}
						of bel <a href="tel:0725200924">072 52 00 924</a>
					</p>
					<Specifications
						specifications={[
							{
								label:
									courseData.acf.reis_informatie_blok
										.datums[0].start_datum,
								icon: (
									<Clock
										winter={HeaderCTX.winterTime}
										color={'#F2F3F4'}
									/>
								),
							},
							{
								label: courseData.acf.reis_informatie_blok.prijs.toLocaleString(
									'nl-NL',
								),
								icon: <Eur color={'#F2F3F4'} />,
							},
						]}
					/>
				</article>

				<form action="">
					<article className={'sign-up-form__column--holder'}>
						<div className={'sign-up-form__column'}>
							<p className={'sign-up-form__column--title'}>
								Reis
							</p>
							<p className={'sign-up-form__column--text'}>
								{courseData.acf.reis_informatie_blok.reis_titel}
							</p>
						</div>
						<div className={'sign-up-form__column'}>
							<p className={'sign-up-form__column--title'}>
								Kosten
							</p>
							<div>
								<p className="sign-up-form__column--text">
									€
									{courseData.acf.reis_informatie_blok.prijs.toLocaleString(
										'nl-NL',
									)}
								</p>
								<input
									type="hidden"
									value={
										courseData.acf.customId
											? courseData.acf.customId
											: courseData.wordpress_id
									}
								/>
							</div>
						</div>
					</article>
					<div className={'sign-up-form__column--holder'}>
						{courseData && courseData.acf ? (
							<fieldset className={'sign-up-form__radio-holder'}>
								<legend
									className={'sign-up-form__radio--title'}
								>
									Verblijf in Hotel Kungshaga
								</legend>

								<label
									htmlFor="two"
									className={'sign-up-form__radio--label'}
								>
									<input
										className={'sign-up-form__radio--radio'}
										type="radio"
										id="two"
										name="type-of-signup"
										defaultChecked
									/>
									<div></div>2 persoonskamer
								</label>
								<label
									htmlFor="update-sign-up"
									className={'sign-up-form__radio--label'}
								>
									<input
										className={'sign-up-form__radio--radio'}
										type="radio"
										id="one"
										name="type-of-signup"
									/>
									<div></div>1 persoonskamer (toeslag)
								</label>
							</fieldset>
						) : null}

						{courseData && courseData.acf ? (
							<fieldset className={'sign-up-form__radio-holder'}>
								<legend
									className={'sign-up-form__radio--title'}
								>
									Vervoer
								</legend>

								<label
									htmlFor="self"
									className={'sign-up-form__radio--label'}
								>
									<input
										className={'sign-up-form__radio--radio'}
										type="radio"
										id="self"
										name="partner"
										defaultChecked
									/>
									<div></div>
									Ik regel zelf vervoer
								</label>
								<label
									htmlFor="touringcar"
									className={'sign-up-form__radio--label'}
								>
									<input
										className={'sign-up-form__radio--radio'}
										type="radio"
										id="touringcar"
										name="partner"
									/>
									<div></div>
									Touringcar + Overtocht Stena line
								</label>
							</fieldset>
						) : null}
					</div>
					<fieldset>
						<legend className={'sign-up-form__column--title'}>
							Schaatsen huren
						</legend>
						<div className={'sign-up-form__select--holder'}>
							<div>
								<label
									htmlFor="experience"
									className={'sign-up-form__radio--title'}
								>
									Maat
								</label>
								<select name={'rental'} id={'rental'}>
									<option value={'selected'}>Geen</option>
									{Array.from(Array(20)).map((x, index) => (
										<option
											key={index + 28}
											value={index + 28}
											id={`size_${index + 28}`}
										>
											{index + 28}
										</option>
									))}
									{/*<>*/}
									{/*	{() => {*/}
									{/*		const arr = new Array(20)*/}
									{/*		console.log(arr)*/}
									{/*		return arr.map((sizes, i) => {*/}
									{/*			const val = 28 + i*/}
									{/*			console.log(val)*/}
									{/*			return (*/}
									{/*				<option*/}
									{/*					value={val}*/}
									{/*					id={`size_${val}`}*/}
									{/*				>*/}
									{/*					{val}*/}
									{/*				</option>*/}
									{/*			)*/}
									{/*		})*/}
									{/*	}}*/}
									{/*</>*/}
								</select>
							</div>
						</div>
					</fieldset>
					{/*<fieldset>*/}
					{/*	<legend className={'sign-up-form__column--title'}>*/}
					{/*		Beschikbare tijden*/}
					{/*	</legend>*/}
					{/*	<div className={'sign-up-form__select--holder'}>*/}
					{/*		<div>*/}
					{/*			<label*/}
					{/*				htmlFor="first-choice"*/}
					{/*				className={'sign-up-form__radio--title'}*/}
					{/*			>*/}
					{/*				1e keus*/}
					{/*			</label>*/}
					{/*			{createTimeSlotOption('first', currentVariant)}*/}
					{/*		</div>*/}
					{/*		<div>*/}
					{/*			<label*/}
					{/*				htmlFor="second-choice"*/}
					{/*				className={'sign-up-form__radio--title'}*/}
					{/*			>*/}
					{/*				2e keus*/}
					{/*			</label>*/}
					{/*			{createTimeSlotOption('second', currentVariant)}*/}
					{/*		</div>*/}
					{/*		<div>*/}
					{/*			<label*/}
					{/*				htmlFor="third-choice"*/}
					{/*				className={'sign-up-form__radio--title'}*/}
					{/*			>*/}
					{/*				3e keus*/}
					{/*			</label>*/}
					{/*			{createTimeSlotOption('third', currentVariant)}*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</fieldset>*/}
					{/*{courseData.acf.tonen.includes('niveautabel') ? (*/}
					{/*	<fieldset>*/}
					{/*		<legend className={'sign-up-form__column--title'}>*/}
					{/*			Ervaring*/}
					{/*		</legend>*/}
					{/*		<div className={'sign-up-form__select--holder'}>*/}
					{/*			<div>*/}
					{/*				<label*/}
					{/*					htmlFor="experience"*/}
					{/*					className={'sign-up-form__radio--title'}*/}
					{/*				>*/}
					{/*					Schaats ervaring*/}
					{/*				</label>*/}
					{/*				<select*/}
					{/*					name={'experience'}*/}
					{/*					id={'experience'}*/}
					{/*				>*/}
					{/*					<option*/}
					{/*						value={'selected'}*/}
					{/*						disabled*/}
					{/*						hidden*/}
					{/*					>*/}
					{/*						Ervaring*/}
					{/*					</option>*/}
					{/*					<option value="1" id={'experience_1'}>*/}
					{/*						Onervaren*/}
					{/*					</option>*/}
					{/*					<option value="2" id={'experience_2'}>*/}
					{/*						Beetje ervaren*/}
					{/*					</option>*/}
					{/*					<option value="3" id={'experience_3'}>*/}
					{/*						Redelijk ervaren*/}
					{/*					</option>*/}
					{/*					<option value="4" id={'experience_4'}>*/}
					{/*						Ervaren*/}
					{/*					</option>*/}
					{/*					<option value="5" id={'experience_5'}>*/}
					{/*						Zeer ervaren*/}
					{/*					</option>*/}
					{/*					<option value="6" id={'experience_6'}>*/}
					{/*						Expert*/}
					{/*					</option>*/}
					{/*				</select>*/}
					{/*			</div>*/}
					{/*		</div>*/}
					{/*	</fieldset>*/}
					{/*) : null}*/}
					{/*{courseData &&*/}
					{/*courseData.acf &&*/}
					{/*courseData.acf.tonen &&*/}
					{/*courseData.acf.tonen.includes('geboortedatum') ? (*/}
					{/*	<fieldset>*/}
					{/*		<legend className={'sign-up-form__column--title'}>*/}
					{/*			Leeftijd*/}
					{/*		</legend>*/}
					{/*		<div className={'sign-up-form__select--holder'}>*/}
					{/*			<div>*/}
					{/*				<label*/}
					{/*					htmlFor="birthdate"*/}
					{/*					className={'sign-up-form__radio--title'}*/}
					{/*				>*/}
					{/*					Geboortedatum*/}
					{/*				</label>*/}
					{/*				<input*/}
					{/*					required*/}
					{/*					type="date"*/}
					{/*					id="birthdate"*/}
					{/*					name="birthdate"*/}
					{/*					value="1990-01-01"*/}
					{/*					min="1915-01-01"*/}
					{/*					max={`${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}`}*/}
					{/*				/>*/}
					{/*			</div>*/}
					{/*		</div>*/}
					{/*	</fieldset>*/}
					{/*) : null}*/}
					{/*{courseData.acf.tonen.includes('eerder-cursus') ? (*/}
					{/*	<fieldset>*/}
					{/*		<legend className={'sign-up-form__column--title'}>*/}
					{/*			Eerdere cursus*/}
					{/*		</legend>*/}
					{/*		<div className={'sign-up-form__text--holder'}>*/}
					{/*			<label*/}
					{/*				htmlFor="eerder-cursus"*/}
					{/*				className={'sign-up-form__radio--title'}*/}
					{/*			>*/}
					{/*				Uw registratienummer*/}
					{/*			</label>*/}
					{/*			<small>*/}
					{/*				Heeft u al eerder een cursus bij In Balans*/}
					{/*				gevolgd? Voor snelle verwerking van dit*/}
					{/*				formulier is uw registratienummer*/}
					{/*				noodzakelijk.*/}
					{/*			</small>*/}
					{/*			<input*/}
					{/*				type="text"*/}
					{/*				id="eerder-cursus"*/}
					{/*				name="eerder-cursus"*/}
					{/*				placeholder={'Uw registratienummer'}*/}
					{/*			/>*/}
					{/*		</div>*/}
					{/*	</fieldset>*/}
					{/*) : null}*/}
					<fieldset>
						<legend className={'sign-up-form__column--title'}>
							Gegevens deelnemer
						</legend>
						<div className={'sign-up-form__text--holder'}>
							<label
								htmlFor="firstname"
								className={'sign-up-form__radio--title'}
							>
								Voornaam
							</label>
							<input
								required
								type="text"
								id="firstname"
								name="firstname"
								placeholder={'Voornaam'}
							/>
						</div>
						<div className={'sign-up-form__text--holder'}>
							<label
								htmlFor="name"
								className={'sign-up-form__radio--title'}
							>
								Naam
							</label>
							<input
								required
								type="text"
								id="name"
								name="name"
								placeholder={'Volledige naam'}
							/>
						</div>
						<div className={'sign-up-form__text--holder'}>
							<label
								htmlFor="email"
								className={'sign-up-form__radio--title'}
							>
								Emailadres
							</label>
							<input
								required
								type="email"
								id="email"
								name="email"
								placeholder={'Emailadres'}
							/>
						</div>
						<div className={'sign-up-form__text--holder'}>
							<label
								htmlFor="address"
								className={'sign-up-form__radio--title'}
							>
								Straat + nr
							</label>
							<input
								required
								type="text"
								id="address"
								name="address"
								placeholder={'Straatnaam 12'}
							/>
						</div>
						<div className={'sign-up-form__text--holder'}>
							<label
								htmlFor="postalcode"
								className={'sign-up-form__radio--title'}
							>
								Postcode
							</label>
							<input
								required
								type="text"
								id="postalcode"
								name="postalcode"
								placeholder={'1234AA'}
							/>
						</div>
						<div className={'sign-up-form__text--holder'}>
							<label
								htmlFor="place"
								className={'sign-up-form__radio--title'}
							>
								Woonplaats
							</label>
							<input
								required
								type="text"
								id="place"
								name="place"
								placeholder={'Alkmaar'}
							/>
						</div>
						<div className={'sign-up-form__text--holder'}>
							<label
								htmlFor="phone"
								className={'sign-up-form__radio--title'}
							>
								Telefoonnummer
							</label>
							<input
								required
								type="text"
								id="phone"
								name="phone"
								placeholder={'0612345678'}
							/>
						</div>
					</fieldset>
					<Button
						text={'Inschrijven'}
						type={'submit'}
						className={'secondary'}
					/>
				</form>
			</section>
		</React.Fragment>
	)
}
