import React, { useContext } from 'react'
import SecondaryLayout from '../layouts/SecondaryLayout'
import FullWidthImageCarousel from '../components/FullWidthImageCarousel/FullWidthImageCarousel'
import PagePadding from '../components/PagePadding/PagePadding'
import TripDayPlanning from '../components/TripDayPlanning/TripDayPlanning'
import ReviewCarousel from '../components/ReviewCarousel/ReviewCarousel'
import HeroBigAsset from '../components/HeroBigAsset/HeroBigAsset'
import InfoBlock from '../components/InfoBlock/InfoBlock'
import TextWithButtons from '../components/TextWithButtons/TextWithButtons'
import SignUpFormModal, {
	SignUpFormContextProvider,
	SignUpFormContext,
} from '../components/SignUpFormModal/SignUpFormModal'

import '../styles/normalize.scss'
import '../styles/variables.scss'
import '../styles/fonts.scss'
import '../styles/global.scss'
import '../styles/link.scss'
import { graphql } from 'gatsby'
import Age from '../images/icons/Age'
import ReactHtmlParser from 'react-html-parser'
import Expedition from '../components/SignUpForms/Expedition/Expedition'
import { Helmet } from 'react-helmet'
import { HeaderContext } from '../components/Header/Header'

export default function Vacation(props) {
	const wordpressAcfReis = props.data.wordpressAcfReis
	const allWordpressAcfReview = props.data.allWordpressAcfReview
	const HeaderCTX = useContext(HeaderContext)

	const reviewCards = allWordpressAcfReview.edges.map((review) => {
		return {
			quote: review.node.acf.review,
			cite: {
				age: review.node.acf.age,
				name: review.node.acf.name,
				level: review.node.acf.level,
			},
		}
	})

	const Info = {
		title: wordpressAcfReis.acf.reis_blok.reis_title,
		course_description: ReactHtmlParser(
			wordpressAcfReis.acf.reis_blok.reis_beschrijving,
		),
		information: [
			{
				title: 'Bijzonderheden',
				content: ReactHtmlParser(
					wordpressAcfReis.acf.reis_informatie_kolommen
						.bijzonderheden_beschrijving,
				),
			},
			{
				title: 'Verblijf',
				content: ReactHtmlParser(
					wordpressAcfReis.acf.reis_informatie_kolommen
						.verblijf_beschrijving,
				),
			},
			{
				title: 'Vervoer',
				content: ReactHtmlParser(
					wordpressAcfReis.acf.reis_informatie_kolommen
						.vervoer_beschrijving,
				),
			},
		],
		specs: [
			{
				label:
					wordpressAcfReis.acf.reis_informatie_blok.leeftijd >= 17
						? 'Volwassenen'
						: !wordpressAcfReis.acf.reis_informatie_blok.leeftijd
						? 'Iedereen'
						: 'Jeugd',
				icon: (
					<Age
						winter={HeaderCTX.winterTime}
						text={
							wordpressAcfReis.acf.reis_informatie_blok.leeftijd
						}
					/>
				),
			},
		],
		practicalData: wordpressAcfReis.acf.reis_informatie_blok,
	}

	const timeline = wordpressAcfReis.acf.reis_tijdlijn.map((day) => {
		if (!day.reis_dag.de_dag.length) {
			return undefined
		}
		let formattedDays = {}

		day.reis_dag.de_dag.forEach((dag, i) => {
			const dagFoto = dag.foto
			if (dagFoto) {
				const data = {
					['image' + i]: {
						src: dag.foto.source_url,
						alt: dag.foto.alt_text,
					},
					['article' + i]: {
						title: dag.titel,
						text: dag.beschrijving,
					},
				}

				formattedDays = { ...formattedDays, ...data }
			}
			return undefined
		})

		return {
			day: {
				name: `Dag ${day.reis_dag.dagnummer}`,
				number: day.reis_dag.dagnummer,
				title: day.reis_dag.dag_titel,
			},
			content: formattedDays,
		}
	})

	const images = wordpressAcfReis.acf.sfeer_fotos.map((foto) => ({
		src: foto.source_url,
		alt: foto.alt_text,
	}))

	function strip(html) {
		if (typeof DOMParser !== 'undefined') {
			const doc = new DOMParser().parseFromString(html, 'text/html')
			return doc.body.textContent || ''
		}
	}

	return (
		<SignUpFormContextProvider>
			<SecondaryLayout>
				<Helmet>
					<title>In Balans Alkmaar | {Info.title}</title>
					<meta
						name="description"
						content={
							'Ga mee op all-inclusive sportreizen naar Zweden of de Ardennen met In Balans Alkmaar.'
						}
					/>
				</Helmet>
				{wordpressAcfReis.acf.sfeer_fotos &&
				wordpressAcfReis.acf.sfeer_fotos[0] &&
				wordpressAcfReis.acf.sfeer_fotos[0].source_url ? (
					<HeroBigAsset
						image={{
							small:
								wordpressAcfReis.acf.sfeer_fotos &&
								wordpressAcfReis.acf.sfeer_fotos[0] &&
								wordpressAcfReis.acf.sfeer_fotos[0].source_url
									? wordpressAcfReis.acf.sfeer_fotos[0]
											.source_url
									: '/images/inbalans-image-10.jpg',
							large:
								wordpressAcfReis.acf.sfeer_fotos &&
								wordpressAcfReis.acf.sfeer_fotos[0] &&
								wordpressAcfReis.acf.sfeer_fotos[0].source_url
									? wordpressAcfReis.acf.sfeer_fotos[0]
											.source_url
									: '/images/inbalans-image-10.jpg',
							alt:
								wordpressAcfReis.acf.sfeer_fotos &&
								wordpressAcfReis.acf.sfeer_fotos[0] &&
								wordpressAcfReis.acf.sfeer_fotos[0].alt_text &&
								wordpressAcfReis.acf.sfeer_fotos[0].alt_text
									.length > 2
									? wordpressAcfReis.acf.sfeer_fotos[0]
											.alt_text
									: 'Cursus bij In Balans Alkmaar',
						}}
						course={true}
						text={strip(
							wordpressAcfReis.acf.reis_blok.reis_beschrijving,
						)}
					/>
				) : null}
				<PagePadding>
					<SignUpFormContext.Consumer>
						{({ toggleModal }) => (
							<TextWithButtons
								localProps={[
									{
										title:
											wordpressAcfReis.acf.reis_blok
												.reis_title,
										text:
											wordpressAcfReis.acf
												.reis_informatie_blok
												.algemene_beschrijving,
										buttonPrimary: {
											text: 'Beleef de reis',
											to: '#reis',
										},
										buttonSecondary: {
											text: 'nu inschrijven',
											action: () => toggleModal(),
											download:
												wordpressAcfReis.acf.bijlage &&
												wordpressAcfReis.acf.bijlage
													.url &&
												wordpressAcfReis.acf.bijlage.url
													.source_url
													? wordpressAcfReis.acf
															.bijlage.url
															.source_url
													: undefined,
										},
										buttonTertiary: wordpressAcfReis.acf.inschrijfformulier?.show === 'aan' ? {
											text: "Inschrijfformulier",
											to: wordpressAcfReis.acf.inschrijfformulier.file.source_url
										}: null
									},
								]}
							/>
						)}
					</SignUpFormContext.Consumer>
					<InfoBlock course={Info} />
				</PagePadding>
				<FullWidthImageCarousel
					className={'full-width__image-carousel'}
					images={images}
				/>

				{reviewCards.length >= 3 && (
					<ReviewCarousel
						className={'review__carousel'}
						cards={reviewCards}
					/>
				)}

				<PagePadding>
					<TripDayPlanning cards={timeline} variant={'Dag'} />
				</PagePadding>
			</SecondaryLayout>
			<SignUpFormModal>
				<Expedition courseData={wordpressAcfReis} days={null} />
			</SignUpFormModal>
		</SignUpFormContextProvider>
	)
}

export const query = graphql`
	query($wordpress_id: Int!) {
		wordpressAcfReis(wordpress_id: { eq: $wordpress_id }) {
			wordpress_id
			acf {
				intro_text
				reis_id
				reis_informatie_blok {
					aantal_dagen
					algemene_beschrijving
					leeftijd
					prijs
					reis_titel
					datums {
						eind_datum
						gegarandeerd_of
						start_datum
					}
				}
				reis_blok {
					reis_beschrijving
					reis_title
					reis_photo {
						alt_text
						source_url
					}
				}
				reis_informatie_kolommen {
					bijzonderheden_beschrijving
					verblijf_beschrijving
					vervoer_beschrijving
				}
				reis_tijdlijn {
					reis_dag {
						dag_titel
						dagnummer
						de_dag {
							beschrijving
							titel
							foto {
								alt_text
								source_url
							}
						}
					}
				}
				sfeer_fotos {
					alt_text
					source_url
				}
				bijlage {
					url {
						source_url
					}
				}
				inschrijfformulier {
					file {
						source_url
					}
					show
				}
			}
		}
		allWordpressAcfReview(
			filter: { acf: { type_review: { eq: "Vakanties" } } }
		) {
			edges {
				node {
					acf {
						age
						level
						name
						review
					}
				}
			}
		}
	}
`
